var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseLayout', {
    staticClass: "px-0",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-icon', {
          staticClass: "py-1"
        }, [_vm._v("mdi-format-list-bulleted")]), _vm._v(" Kommunikasjonsmaler ")];
      },
      proxy: true
    }])
  }, [_vm.modalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 1200
    },
    on: {
      "close": function close($event) {
        return _vm.closeModal(_vm.modalData);
      }
    }
  }, [_c('CommunicationTemplateModal', {
    attrs: {
      "headline": _vm.modalData.modalHeadline,
      "modalType": _vm.modalData.modalType,
      "existingItemModal": _vm.modalData.existingItem,
      "existingItemId": _vm.modalData.existingItemId,
      "templateGroups": _vm.templateGroups
    },
    on: {
      "close": function close($event) {
        return _vm.closeModal(_vm.modalData);
      }
    }
  })], 1) : _vm._e(), _vm.modalDataDelete.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 800
    },
    on: {
      "close": function close($event) {
        return _vm.closeModal(_vm.modalDataDelete);
      }
    }
  }, [_c('CommunicationTemplateDeleteModal', {
    attrs: {
      "modalBaseData": _vm.modalDataDelete
    },
    on: {
      "close": function close($event) {
        return _vm.closeModal(_vm.modalDataDelete);
      }
    }
  })], 1) : _vm._e(), [_c('BaseTableFiltered', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.filteredTemplates,
      "search": _vm.search,
      "sort-by": "updated",
      "sort-desc": true
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-col', {
          staticClass: "pl-0 py-0",
          attrs: {
            "cols": "2"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "Malgruppe",
            "items": _vm.templateGroups,
            "item-text": "name",
            "item-value": "id",
            "clearable": ""
          },
          on: {
            "change": _vm.onChangeSelectedFilter
          },
          model: {
            value: _vm.selectedGroupId,
            callback: function callback($$v) {
              _vm.selectedGroupId = $$v;
            },
            expression: "selectedGroupId"
          }
        })], 1), _c('v-col', {
          staticClass: "pl-0 py-0",
          attrs: {
            "cols": "2"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "Status",
            "items": _vm.statusOptions,
            "item-text": "text",
            "item-value": "value",
            "clearable": "",
            "data-cy": "communicationTemplateSelectStatus"
          },
          on: {
            "change": _vm.onChangeSelectedFilter
          },
          model: {
            value: _vm.selectedStatus,
            callback: function callback($$v) {
              _vm.selectedStatus = $$v;
            },
            expression: "selectedStatus"
          }
        })], 1), _c('v-spacer'), _c('v-text-field', {
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Søk",
            "single-line": "",
            "clearable": ""
          },
          model: {
            value: _vm.search,
            callback: function callback($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        }), _c('BaseTooltipIconButton', {
          attrs: {
            "color": "primary",
            "btnText": "Lag ny kommunikasjonsmal",
            "icon": "mdi-plus",
            "data-cy": "openCreateCommunicationTemplate"
          },
          on: {
            "handleClick": function handleClick($event) {
              return _vm.openCreateCommunicationTemplate();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "item.updated",
      fn: function fn(_ref) {
        var value = _ref.value;
        return [_vm._v(" " + _vm._s(_vm.formatRelative(value)) + " ")];
      }
    }, {
      key: "item.isActive",
      fn: function fn(_ref2) {
        var value = _ref2.value;
        return [_vm._v(" " + _vm._s(value ? "Aktiv" : "Inaktiv") + " ")];
      }
    }, {
      key: "actions",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on,
                  attrs = _ref4.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                staticClass: "mr-2",
                attrs: {
                  "data-cy": "displayCommunicationTemplate"
                },
                on: {
                  "click": function click($event) {
                    return _vm.openDisplayCommunicationTemplatePage(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-clipboard-text-search-outline ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Vis " + _vm._s(item.name))])]), item.communicationTemplateGroupType === _vm.TEMPLATE_USER_GROUP_TYPE ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref5) {
              var on = _ref5.on,
                  attrs = _ref5.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                staticClass: "mr-2",
                attrs: {
                  "data-cy": "openEditCommunicationTemplate"
                },
                on: {
                  "click": function click($event) {
                    return _vm.openEditCommunicationTemplate(item, item.name);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-pencil ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Rediger " + _vm._s(item.name))])]) : _vm._e(), item.communicationTemplateGroupType === _vm.TEMPLATE_USER_GROUP_TYPE ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref6) {
              var on = _ref6.on,
                  attrs = _ref6.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                staticClass: "mr-2",
                attrs: {
                  "data-cy": "openDeleteCommunicationTemplate"
                },
                on: {
                  "click": function click($event) {
                    return _vm.openDeleteCommunicationTemplate(item, item.name);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-delete ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Slett " + _vm._s(item.name))])]) : _vm._e()];
      }
    }], null, true)
  })]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }