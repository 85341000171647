import { render, staticRenderFns } from "./CommunicationTemplateListPage.vue?vue&type=template&id=1e669bce&scoped=true&"
import script from "./CommunicationTemplateListPage.vue?vue&type=script&lang=ts&"
export * from "./CommunicationTemplateListPage.vue?vue&type=script&lang=ts&"
import style0 from "./CommunicationTemplateListPage.vue?vue&type=style&index=0&id=1e669bce&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e669bce",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCol,VIcon,VSelect,VSpacer,VTextField,VTooltip})
