var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.modalType
    },
    on: {
      "submit": _vm.handleSubmit,
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-form', {
    ref: "connectForm",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Malgruppe",
      "items": _vm.filteredTemplateGroups,
      "item-text": "name",
      "item-value": "id",
      "rules": _vm.validateNotEmpty,
      "readonly": !_vm.isUserGroupType,
      "required": "",
      "data-cy": "templateModalSelectGroup"
    },
    model: {
      value: _vm.template.communicationTemplateGroupId,
      callback: function callback($$v) {
        _vm.$set(_vm.template, "communicationTemplateGroupId", $$v);
      },
      expression: "template.communicationTemplateGroupId"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Avdeling",
      "value": _vm.currentOrganizationName,
      "readonly": ""
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Malnavn",
      "rules": _vm.validateNotEmpty,
      "required": "",
      "data-cy": "templateModalName"
    },
    model: {
      value: _vm.template.name,
      callback: function callback($$v) {
        _vm.$set(_vm.template, "name", $$v);
      },
      expression: "template.name"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    ref: "subjectTextField",
    attrs: {
      "label": "Tittel",
      "rules": _vm.validateNotEmpty,
      "required": "",
      "counter": 200,
      "data-cy": "templateModalSubject"
    },
    model: {
      value: _vm.template.subject,
      callback: function callback($$v) {
        _vm.$set(_vm.template, "subject", $$v);
      },
      expression: "template.subject"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Variabler",
      "items": _vm.messagingOptions,
      "data-cy": "templateModalSelectVariable"
    },
    model: {
      value: _vm.selectedVariable,
      callback: function callback($$v) {
        _vm.selectedVariable = $$v;
      },
      expression: "selectedVariable"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    staticClass: "mt-2 mr-1",
    attrs: {
      "depressed": "",
      "small": "",
      "data-cy": "addVariableToSubject"
    },
    on: {
      "click": function click($event) {
        return _vm.addVariable(_vm.Field.SUBJECT);
      }
    }
  }, [_vm._v("Legg til variabel i tittel")]), _c('v-btn', {
    staticClass: "mt-2 ml-1",
    attrs: {
      "depressed": "",
      "small": "",
      "data-cy": "addVariableToContent"
    },
    on: {
      "click": function click($event) {
        return _vm.addVariable(_vm.Field.CONTENT);
      }
    }
  }, [_vm._v("Legg til variabel i innhold")])], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('editor', {
    ref: "tuiEditor",
    attrs: {
      "initialValue": _vm.template.content,
      "options": _vm.editorOptions,
      "data-cy": "templateModalContent"
    },
    on: {
      "change": _vm.onEditorChange
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "6"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": _vm.template.isActive ? 'Aktiv' : 'Inaktiv',
      "color": "primary",
      "inset": "",
      "hide-details": ""
    },
    model: {
      value: _vm.template.isActive,
      callback: function callback($$v) {
        _vm.$set(_vm.template, "isActive", $$v);
      },
      expression: "template.isActive"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }