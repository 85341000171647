var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseConfirmModalForm', {
    attrs: {
      "modalBaseData": _vm.modalBaseData
    },
    on: {
      "submit": _vm.deleteCommunicationTemplate,
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Er du sikker på at du vil slette kommunikasjonsmalen? ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }