
import { api } from "@/api/api";
import { ApiGetCommunicationTemplateDto, ApiGetCommunicationTemplateGroupDto } from "@/api/generated/Api";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import CommunicationTemplateModal from "@/components/administration/communication-template/CommunicationTemplateModal.vue";
import CommunicationTemplateDeleteModal from "@/components/administration/communication-template/CommunicationTemplateDeleteModal.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { formatRelative } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { ModalBaseData } from "@/shared/interfaces/ModalBaseData.interface";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import { useRouter } from "@/shared/useHelpers";
import { AdminRouteNames } from "@/shared/enums/RouteNames/adminRouteNamesEnum";
import { localeSortByProperty } from "@/shared/helpers/arrayHelpers";

export default defineComponent({
  name: "CommunicationTemplateListPage",
  components: {
    BaseTooltipIconButton,
    BaseTableFiltered,
    BaseLayout,
    BaseModal,
    CommunicationTemplateModal,
    CommunicationTemplateDeleteModal,
  },
  setup() {
    const search = ref<string>("");
    const router = useRouter();
    const modalData = ref(getInitialModalData());
    const modalDataDelete = ref(getInitialModalData());
    const selectedGroupId = ref<number>();
    const selectedStatus = ref<boolean>();
    const TEMPLATE_USER_GROUP_TYPE = "user";
    const templateGroups = ref<ApiGetCommunicationTemplateGroupDto[]>([]);
    const templates = ref<ApiGetCommunicationTemplateDto[]>([]);
    const filteredTemplates = ref<ApiGetCommunicationTemplateDto[]>([]);

    const getCommunicationTemplateGroups = async () =>
      (await api.communicationtemplategroup.getCommunicationTemplateGroupsAsync()).data;

    const loadCommunicationTemplates = async () => {
      templates.value = (await api.communicationtemplate.getCommunicationTemplatesAsync()).data;
      filteredTemplates.value = templates.value;
    };

    const filterIsActive = (template: ApiGetCommunicationTemplateDto, isActive?: boolean) => {
      if (isActive === undefined) {
        return template;
      }
      return template.isActive === isActive;
    };

    const filterGroup = (template: ApiGetCommunicationTemplateDto, groupId?: number) => {
      if (!groupId) {
        return template;
      }
      return template.communicationTemplateGroupId === groupId;
    };

    const getCommunicationTemplatesByFilter = (groupId?: number, isActive?: boolean) =>
      templates.value
        .filter((template) => filterIsActive(template, isActive))
        .filter((template) => filterGroup(template, groupId));

    const onChangeSelectedFilter = () => {
      filteredTemplates.value = getCommunicationTemplatesByFilter(selectedGroupId.value, selectedStatus.value);
    };

    const openDisplayCommunicationTemplatePage = (item: ApiGetCommunicationTemplateDto) => {
      router.push({
        name: AdminRouteNames.CommunicationTemplate,
        params: {
          communicationTemplateId: item.id?.toString(),
        },
      });
    };

    const openCreateCommunicationTemplate = useOpenModal(ModalType.Add, "kommunikasjonsmal", modalData);
    const openEditCommunicationTemplate = useOpenModal(ModalType.Edit, "kommunikasjonsmal", modalData);
    const openDeleteCommunicationTemplate = useOpenModal(ModalType.Delete, "kommunikasjonsmal", modalDataDelete);

    const closeModal = async (modalData: ModalBaseData) => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        modalData.showModal = false;
        resetModalData();
        selectedGroupId.value = undefined;
        selectedStatus.value = undefined;
        await loadCommunicationTemplates();
      });
    };

    const resetModalData = () => {
      modalData.value = getInitialModalData();
      modalDataDelete.value = getInitialModalData();
    };

    onMounted(async () => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        templateGroups.value = (await getCommunicationTemplateGroups()).sort(localeSortByProperty("name"));
        await loadCommunicationTemplates();
      });
    });

    return {
      headers,
      search,
      modalData,
      modalDataDelete,
      openDisplayCommunicationTemplatePage,
      openCreateCommunicationTemplate,
      openEditCommunicationTemplate,
      openDeleteCommunicationTemplate,
      formatRelative,
      filteredTemplates,
      templateGroups,
      selectedGroupId,
      selectedStatus,
      statusOptions,
      onChangeSelectedFilter,
      closeModal,
      loadCommunicationTemplates,
      TEMPLATE_USER_GROUP_TYPE,
    };
  },
});

const headers = [
  { text: "Navn", value: "name" },
  { text: "Handlinger", value: "actions", sortable: false },
  { text: "Tittel", value: "subject" },
  { text: "Lagt til av", value: "insertedByUserFullName" },
  { text: "Oppdatert", value: "updated" },
  { text: "Status", value: "isActive" },
];

const statusOptions = [
  { text: "Aktiv", value: true },
  { text: "Inaktiv", value: false },
];
